import server from "./server";

// export const getPortalHelpBlogsApi = async ({ cookies, search }) => {
//   const response = await server({ cookies }).get(
//     `/portalHelpBlogs${search || ""}`
//   );
//   return response.data;
// };
export const getHelpCategoriesByTypeApi = async ({ cookies, type }) => {
    const response = await server({ cookies }).get(
        `/portal/helpCenterCategories?type=${type}`
    );
    return response.data;
};
export const getHelpBlogsByCategoryApi = async ({ cookies, id }) => {
    const response = await server({ cookies }).get(
        `/portal/helpCenter/getTopicsByCategory/${id}`
    );
    return response.data;
};
export const getHelpBlogsByTypeApi = async ({
    cookies,
    type,
    filterSource,
}) => {
    const response = await server({ cookies }).get(
        `/portal/helpCenter?filterSource=${filterSource}&type=${type}`
    );
    return response.data;
};
export const getHelpBlogApi = async ({ cookies, slug, type }) => {
    const response = await server({ cookies }).get(
        `/portal/helpCenter/getTopicsBySlug/${type}/${encodeURIComponent(slug)}`
    );
    return response.data;
};
export const getPortalFaqsApi = async ({ cookies }) => {
    const response = await server({ cookies }).get(`/portal/faqs`);
    return response.data;
};
// export const getHelpCenterContentByIdApi = async ({ cookies, id }) => {
//   const response = await server({ cookies }).get(
//     `/portalHelpBlogs/getBlogsByCategory/${id}`
//   );
//   return response.data;
// };
export const postTechnicalSupportsApi = async ({ cookies, data }) => {
    const response = await server({ cookies }).post(
        `/portal/helpTechnicalSupports`,
        data
    );
    return response.data;
};
export const postPortalReservationApi = async ({ cookies, formData }) => {
    const response = await server({ cookies }).post(
        `/portal/reservations`,
        formData
    );
    return response.data;
};
export const addVoteApi = async ({ cookies, id }) => {
    const response = await server({ cookies }).post(
        `/portal/helpBlogs/voting/${id}`
    );
    return response.data;
};
export const addSuggestionApi = async ({ cookies, data }) => {
    const response = await server({ cookies }).post(
        `/portal/helpBlogs/addSuggestion`,
        data
    );
    return response.data;
};
export const addCommentInSuggestionApi = async ({ cookies, data, id }) => {
    const response = await server({ cookies }).post(
        `/portal/helpBlogs/addComment/${id}`,
        data
    );
    return response.data;
};

export const addPortalSubscribersApi = async ({ cookies, data }) => {
    const response = await server({ cookies }).post(
        `/portal/subscribers`,
        data
    );
    return response.data;
};
