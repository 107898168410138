import superAdminServer from "./superAdminServer";

// ================== Categories =================
export const getCategoriesApi = async ({ cookies }) => {
  const response = await superAdminServer({ cookies }).get(`/categories`);
  return response.data;
};
export const getThemesApi = async ({ cookies }) => {
  const response = await superAdminServer({ cookies }).get(`/themes`);
  return response.data;
};
