import { END } from "redux-saga";
import { NextSeo } from "next-seo";
import { useIntl } from "react-intl";
import { wrapper } from "@/store/index";
import {
  getBlogs,
  getCategories,
  getPortalSection,
  getPortalSettings,
  getSeoData,
  getThemes,
} from "@/store/actions";
import { getPackages } from "@/store/packages/actions";
// Import Components

const Hero = dynamic(() => import("@/components/homepage/Hero/Index"), {
  ssr: false,
});
const Features = dynamic(() => import("@/components/homepage/WhyUs/Index"), {
  ssr: false,
});
const Statics = dynamic(() => import("@/components/homepage/Statics/Index"), {
  ssr: false,
});
// import Statics from "@/components/homepage/Statics/Index";

const Section1 = dynamic(
  () => import("@/components/homepage/Sections/Section-1/Index"),
  {
    ssr: false,
  }
);
// import Section1 from "@/components/homepage/Sections/Section-1/Index";

const Section2 = dynamic(
  () => import("@/components/homepage/Sections/Section-2/Index"),
  {
    ssr: false,
  }
);
// import Section2 from "@/components/homepage/Sections/Section-2/Index";

const Section3 = dynamic(
  () => import("@/components/homepage/Sections/Section-3/Index"),
  {
    ssr: false,
  }
);
// import Section3 from "@/components/homepage/Sections/Section-3/Index";

const Section4 = dynamic(
  () => import("@/components/homepage/Sections/Section-4/Index"),
  {
    ssr: false,
  }
);
// import Section4 from "@/components/homepage/Sections/Section-4/Index";

const Section5 = dynamic(
  () => import("@/components/homepage/Sections/Section-5/Index"),
  {
    ssr: false,
  }
);
// import Section5 from "@/components/homepage/Sections/Section-5/Index";

const How = dynamic(() => import("@/components/homepage/How/Index"), {
  ssr: false,
});
// import How from "@/components/homepage/How/Index";

const Special = dynamic(() => import("@/components/homepage/Special/Index"), {
  ssr: false,
});
// import Special from "@/components/homepage/Special/Index";

const Opinions = dynamic(
  () => import("@/components/homepage/CustomerReviews/Index"),
  {
    ssr: false,
  }
);
// import Opinions from "@/components/homepage/CustomerReviews/Index";

const Blogs = dynamic(() => import("@/components/homepage/blogHome/Index"), {
  ssr: false,
});
// import Blogs from "@/components/homepage/blogHome/Index";

const Departs = dynamic(
  () => import("@/components/homepage/Departments/Index"),
  {
    ssr: false,
  }
);
// import Departs from "@/components/homepage/Departments/Index";

import dynamic from "next/dynamic";
// import { FixedSizeList, VariableSizeList } from "react-window";
// import AutoSizer from "react-virtualized-auto-sizer";
import { useEffect, useRef } from "react";

const Home = ({
  bannerOne,
  whyUs,
  counter,
  section1,
  section2,
  section3,
  section4,
  section5,
  blogs,
  howToWork,
  companiesData,
  sectionsToUseData,
  customeropinions,
  seoDataForPage,
}) => {
  const { locale } = useIntl();
  // const { portalSection } = useSelector((state) => state.portalSection);
  // const { seoData } = useSelector((state) => state.portalSettings);
  // ========== SEO Data ==========

  // ========== Portal Sections ==========
  const listRef = useRef(null);

  // const [height, setHeight] = useState(500);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(0); // Scroll to the top on mount
    }

    //---virtualization attempt---
    // const handleResize = () => {
    //   setHeight(window.innerHeight);
    // };
    // handleResize();
    // if (window !== undefined) {
    //   window.addEventListener("resize", handleResize);
    // }
    // return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <NextSeo
        title={`${seoDataForPage?.title?.[locale]}`}
        additionalMetaTags={[
          {
            name: "keywords",
            content: seoDataForPage?.keywords?.[locale],
          },
          {
            name: "description",
            content: seoDataForPage?.description?.[locale],
          },
        ]}
        description={seoDataForPage?.description?.[locale]}
      />
      {/* <FixedSizeList
        height={height}
        itemCount={16}
        itemSize={300}
        width={"100%"}
        ref={listRef}
      > */}
      <Hero bannerOne={bannerOne} />
      <Features data={whyUs} />
      <Statics counter={counter} />
      <Section1 data={section1} />
      <Section2 data={section2} />
      <Section3 data={section3} />
      <Section4 data={section4} />
      <Section5 data={section5} />
      <How data={howToWork} />
      <Special />
      <Departs
        sectionsToUseData={sectionsToUseData}
        companiesData={companiesData}
      />
      <Opinions customeropinions={customeropinions} />
      <Blogs blogstitle={blogs} />
      {/* </FixedSizeList> */}
    </>
  );
};

export const getStaticProps = wrapper.getStaticProps((store) => {
  return async () => {
    store.dispatch(getPortalSettings({ cookies: {} }));
    store.dispatch(getSeoData({ cookies: {} }));
    store.dispatch(getCategories({ cookies: {} }));
    store.dispatch(getThemes({ cookies: {} }));
    store.dispatch(getPackages({ cookies: {} }));
    store.dispatch(getPortalSection({ cookies: {} }));
    store.dispatch(getBlogs({ cookies: {}, page: 1, limit: 5 }));
    store.dispatch(END);
    await store.sagaTask.toPromise();

    const portalSection = store.getState()?.portalSection?.portalSection || [];
    const seoData = store.getState()?.portalSettings?.seoData || [];

    const bannerOne =
      portalSection?.find((item) => item?.slug === "hero") || {};
    const whyUs =
      portalSection?.find((item) => item?.slug === "advantages") || {};
    const counter =
      portalSection?.find((item) => item?.slug === "counter") || {};
    const section1 =
      portalSection?.find((item) => item?.slug === "feature-1") || {};
    const section2 =
      portalSection?.find((item) => item?.slug === "feature-2") || {};
    const section3 =
      portalSection?.find((item) => item?.slug === "feature-3") || {};
    const section4 =
      portalSection?.find((item) => item?.slug === "feature-4") || {};
    const section5 =
      portalSection?.find((item) => item?.slug === "feature-5") || {};
    const blogs = portalSection?.find((item) => item?.slug === "blogs") || {};
    const howToWork =
      portalSection?.find((item) => item?.slug === "howtowork") || {};
    const companiesData =
      portalSection?.find((item) => item?.slug === "companies") || {};
    const sectionsToUseData =
      portalSection?.find((item) => item?.slug === "sections-to-use") || {};
    const customeropinions =
      portalSection?.find((item) => item?.slug === "customer-opinions") || {};
    const seoDataForPage =
      seoData?.content?.items?.find((item) => item?.pageName === "home") || {};

    return {
      props: {
        bannerOne,
        whyUs,
        counter,
        section1,
        section2,
        section3,
        section4,
        section5,
        blogs,
        howToWork,
        companiesData,
        sectionsToUseData,
        customeropinions,
        seoDataForPage,
      },
      revalidate: 1,
    };
  };
});

export default Home;
