import server from "./server";

// ================== Categories =================
export const getPortalSectionsApi = async ({ cookies }) => {
  const response = await server({ cookies }).get(`/portal/sections`);
  return response.data;
};
export const getPolicyApi = async ({ cookies }) => {
  const response = await server({ cookies }).get(
    `/portal/sections/getSectionBySlug/policy`
  );
  return response.data;
};
export const getTermsApi = async ({ cookies }) => {
  const response = await server({ cookies }).get(
    `/portal/sections/getSectionBySlug/terms`
  );
  return response.data;
};
export const getSeoDataApi = async ({ cookies }) => {
  const response = await server({ cookies }).get(
    `/portal/sections/getSectionBySlug/seo-data`
  );
  return response.data;
};

// ================== Opinions =================
// export const getCustomerOpinionsApi = async ({ cookies }) => {
//   const response = await server({ cookies }).get(
//     `/portalSections/getSectionBySlug/customeropinions`
//   );
//   return response.data;
// };
